import React, { useState, useContext, useEffect } from "react";
import "../../assets/css/ongoingfreights.scss";
import ic_options from "../../assets/images/ic_options.svg";
import { Link } from "react-router-dom";
import ic_trucks from "../../assets/images/Skyscrapers.jpg";
import ic_dropdown_arrow from "../../assets/images/ic_dropdown_arrow.svg";
import { AppContext } from "../../context/user/AppContext";
import { postWithAuthCallWithErrorResponse } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import Loader from "../../sharedComponent/Loader";

const CompleteFreights = () => {
  const [completeFreightsDetails, setCompleteFreightsDetails] = useState([]);
  const { mainClass, customerData } = useContext(AppContext);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getcompleteFreightsDetails();
  }, []);
  const getcompleteFreightsDetails = () => {
    setLoading(true);

    postWithAuthCallWithErrorResponse(
      ApiConfig.COMPLETED_FRIGHT,
      JSON.stringify({
        ...customerData,
      })
    ).then((res) => {
      if (res.json.message === 
        "Invalid user authentication,Please try to relogin with exact credentials.") {
          localStorage.clear();
          window.open("/login", "_self");
      }
      setLoading(false);
      if (res.json.result) setCompleteFreightsDetails(res.json.load_list);
    });
  };

  return (
    <React.Fragment>
      <div style={{ margin: "20px" }}>
        <div className="  col-md-6  mb-3   mb-3 ">
          <div className="">
            <div
              className=""
              style={{
                width: "1160px",
                overflow: "auto",
                height: "83vh",
              }}
            >
              {/* <ProgressBar progress={progress} /> */}
              {loading ? (
                <>
                  <Loader />
                </>
              ) : (
                <table className="dataList">
                  <thead>
                    <tr>
                      <th>{/* <label>Start City</label> */}</th>
                      <th>
                        <label>Start City</label>
                      </th>
                      <th>
                        <label>Delivery Date</label>
                      </th>
                      {/* <th>
                        <label>End Date</label>
                      </th> */}
                      <th>
                        <label>Status</label>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {completeFreightsDetails &&
                    completeFreightsDetails.length > 0 ? (
                      completeFreightsDetails
                      .filter(e => e.trip_status === 'trip_completed' 
                        || e.trip_status==='trip_partially_completed')
                      .map((completeFreight, index) => {
                        return (
                          <tr
                            className="Rowbgcolor p-2"
                            key={"completeFreight" + index}
                          >
                            <td>
                              <div className="d-flex justify-content-between">
                                <div className="d-flex justify-content-between">
                                  <img
                                    style={{
                                      width: "40px",
                                      height: "40px",
                                      borderRadius: "50%",
                                    }}
                                    src={
                                      ApiConfig.BASE_URL_FOR_IMAGES +
                                      completeFreight.trip_image
                                    }
                                    alt=""
                                    className="card-rsponsive-img"
                                  />
                                  <h5 className="my-free ms-3">
                                    {completeFreight.trip_reference_no}
                                  </h5>
                                </div>
                              </div>
                            </td>
                            <td>
                              {completeFreight.trip_start_city +
                                " - " +
                                completeFreight.trip_end_city}
                            </td>
                            <td id="trip_start_date">
                              {completeFreight.trip_delivery_date}
                            </td>
                            {/* <td id="trip_end_date">
                              {completeFreight.trip_end_date}
                            </td> */}
                            <td id="trip_status">
                              {completeFreight.trip_status}
                            </td>
                            <td id="">
                              <div className="option-dropdown dropdown p-2">
                                <a
                                  href=""
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <img src={ic_options} alt="" />
                                </a>
                                <ul className="dropdown-menu ">
                                  <li>
                                    <Link
                                      to="/GPS_Shipper"
                                      className="text-decoration-none"
                                    >
                                      <button
                                        className="dropdown-item"
                                        type="button"
                                      >
                                        View in Map
                                        <img
                                          src={ic_dropdown_arrow}
                                          className="arrowvehicle"
                                          alt=""
                                        />
                                      </button>
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      to="/ViewDetailsFreight"
                                      className="text-decoration-none"
                                      state={completeFreight}
                                    >
                                      <button
                                        className="dropdown-item"
                                        type="button"
                                      >
                                        View Details
                                      </button>
                                    </Link>
                                  </li>
                                  {/* <li>
                                    <button
                                      className="dropdown-item"
                                      type="button"
                                    >
                                      Call
                                    </button>
                                  </li> */}
                                </ul>
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <span className="text-danger">No data found</span>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CompleteFreights;
