import React, { useContext, useState, useEffect } from "react";
import profile from "../../assets/images/Skyscrapers.jpg";
import document_image from "../../assets/images/vehiclesDetails/document_image.png";
import ic_options_menu from "../../assets/images/ic_options.svg";
import { AppContext } from "../../context/user/AppContext";
import ApiConfig from "../../api/ApiConfig";
import Loader from "../../sharedComponent/Loader";
import { Link } from "react-router-dom";

const Profile = () => {
  const { mainClass, profileDetails } = useContext(AppContext);
  console.log("ani " + JSON.stringify(profileDetails));
  const basicInfo = profileDetails.profile && profileDetails.profile.basic_info;
  const personInfo =
    profileDetails.profile && profileDetails.profile.contact_person;
  const addressInfo = profileDetails.profile && profileDetails.profile.address;
  const docInfo = profileDetails.profile && profileDetails.profile.documents;
  useEffect(() => {
    console.log("fahi");
  }, [])
  return (
    <>
      <main className={`main-profile-section  ${mainClass}`} id="cxMain">
        <div className="prfile-body-section common-body">
          <div className="row">
            <div className="profile-banner">
              <div className="banner-img d-flex justify-content-end">
                <Link
                  className="card-heading text-decoration-none m-3"
                  to="/EditProfile"
                >
                  Edit
                </Link>
              </div>
              <div className="details-profile">
                <div className="profile-img">
                  <img
                    src={
                      ApiConfig.BASE_URL_FOR_IMAGES +
                      (basicInfo && basicInfo.profile_pic
                        ? basicInfo && basicInfo.profile_pic
                        : profile)
                    }
                    alt=""
                  />{" "}
                  <span className="name-profile mt-2">
                    {basicInfo && basicInfo.company_name}
                  </span>
                </div>
                {/* BASIC INFO */}
                <div className=" profile-basic-info row mt-4">
                  <h5 className="card-heading">Basic Info</h5>
                  <hr />
                  <div className="col-lg-3 col-sm-6 mt-2">
                    <label htmlFor="">Company Name</label>
                    <p className="data">
                      {basicInfo && basicInfo.company_name}
                    </p>
                  </div>
                  {/* <div className="col-lg-3 col-sm-6 mt-2">
                    <label htmlFor="">Company Type</label>
                    <p className="data">
                      {basicInfo && basicInfo.business_area}
                    </p>
                  </div> */}
                </div>
                {/* CONTACT PORSON */}
                <div className="profile-contact-person row mt-4">
                  <h5 className="card-heading">Contact Person</h5>
                  <hr />
                  {/* <div className="col-lg-3 col-sm-6 mt-2">
                    <label htmlFor="">Name</label>
                    <p className="data">{personInfo && personInfo.name}</p>
                  </div>
                  <div className="col-lg-3 col-sm-6 mt-2">
                    <label htmlFor="">Responsibility</label>
                    <p className="data">
                      {personInfo && personInfo.responsibility}
                    </p>
                  </div> */}
                  <div className="col-lg-3 col-sm-6 mt-2">
                    <label htmlFor="">Phone Number</label>
                    <p className="data">{personInfo && personInfo.phone}</p>
                  </div>
                  <div className="col-lg-3 col-sm-6 mt-2">
                    <label htmlFor="">Email</label>
                    <p className="data">{personInfo && personInfo.email}</p>
                  </div>
                </div>
                {/* ADDRESS */}
                <div className="profile-address row mt-4">
                  <h5 className="card-heading">Address</h5>
                  <hr />
                  {/* <div className="col-lg-3 col-sm-6 mt-2">
                    <label htmlFor="">Country</label>
                    <p className="data">{addressInfo && addressInfo.country}</p>
                  </div> */}
                  <div className="col-lg-3 col-sm-6 mt-2">
                    <label htmlFor="">Region</label>
                    <p className="data">{addressInfo && addressInfo.region}</p>
                  </div>
                  <div className="col-lg-3 col-sm-6 mt-2">
                    <label htmlFor="">Phone </label>
                    <p className="data">{addressInfo && addressInfo.phone}</p>
                  </div>
                  <div className="col-lg-3 col-sm-6 mt-2">
                    <label htmlFor="">Alternative Phone</label>
                    <p className="data">
                      {addressInfo && addressInfo.alternate_phone}
                    </p>
                  </div>
                  <div className="col-lg-3 col-sm-6 mt-2">
                    <label htmlFor="">Email</label>
                    <p className="data">{addressInfo && addressInfo.email}</p>
                  </div>
                  {/* <div className="col-lg-3 col-sm-6 mt-2">
                    <label htmlFor="">P.O.Box</label>
                    <p className="data">{addressInfo && addressInfo.po_box}</p>
                  </div> */}
                </div>
                {/* Documents */}
                {/*  */}
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Profile;
