import React, { useState, useEffect, useContext } from "react";
import circle_cross from "../../assets/images/circle_cross.svg";
import ic_remove from "../../assets/images/ic_remove.svg";
import { Link, useNavigate } from "react-router-dom";
import DragAndDropImages from "../../sharedComponent/DragAndDropImages";
import { AppContext } from "../../context/user/AppContext";
import {
  multipartPostCallWithErrorResponse,
  postMultipartWithAuthCallWithErrorResponse,
  postWithAuthCallWithErrorResponse,
} from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import swal from "sweetalert";
import DragAndDrop from "../../sharedComponent/DragAndDrop";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import Barloader from "../../sharedComponent/BarLoader";
const AddOfferGoods = () => {
  
  const navigate = useNavigate();
  const { customerData, mainClass, file, profileDetails, setProfileDetails } =
    useContext(AppContext);
  const cargoDelivery = ['Yes', 'No'];
  const [cargoDeliveryValue, setCargoDeliveryValue] = useState("Yes");
  const [corporateCustomer, setCorporateCustomer] = useState("No");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const allowedFileTypes = ["application/pdf", "image/png", "image/jpeg"];
  const [bill_of_landing, setBill_of_landing] = useState({});
  const [packing_list, setPacking_list] = useState({});
  const [trade_license, setTrade_license] = useState({});
  const [tax_id_no, setTax_id_no] = useState({});
  const [vat_reg_certificate, setVat_reg_certificate] = useState({});
  const [insurance, setInsurance] = useState({});
  const [cargoType, setCargoType] = useState([]);
  const [deliveryType, setDeliveryType] = useState([]);
  const [containerTypes, setContainerTypes] = useState([]);
  const [units, setUnits] = useState([]);
  const [from_address, setFrom_Address] = useState("");
  const [to_address, setTo_Address] = useState("");
  const [loading, setLoading] = useState(false);
  const [fileSize, setFileSize] = useState("");
  const personInfo =
    profileDetails.profile && profileDetails.profile.basic_info;
  const [from_Coordinates, setFrom_Coordinates] = useState({
    from_lat: "",
    from_lon: "",
  });
  const [to_Coordinates, setTo_Coordinates] = useState({
    to_lat: "",
    to_lon: "",
  });
  
  const [state, setState] = useState({
    start_date: "",
    delivery_date: "",
    // start_time: "",
    from_lat: "",
    from_lon: "",
    from_address: "",
    to_address: "",
    end_date: "",
    // end_time: "",
    to_lat: "",
    to_lon: "",
    company_name: personInfo?.company_name,
    cargo_type: "",
    quantity: "",
    other_cargo_type: "",
    units: "",
    delivery_types: "",
    packing_list: "",
    bill_of_landing: "",
    insurance: "",
    trade_license: "",
    vat_reg_certificate: "",
    agreement_abay: "",
    tax_id_no: "",
    load_commercial_invoice: "",
    images: [],
    container_type: "",
    unit_measurment: "",
  });

  const [errMsg, setErrMsg] = useState({
    start_date: "",
    delivery_date: "",
    corporateCustomer: "",
    // start_time: "",
    from_lat: "",
    from_lon: "",
    from_address: "",
    to_address: "",
    end_date: "",
    // end_time: "",
    to_lat: "",
    to_lon: "",
    images: "",
    company_name: "",
    cargo_type: "",
    is_cargo_delivery: "",
    other_cargo_type: "",
    quantity: "",
    units: "",
    delivery_types: "",
    packing_list: "",
    bill_of_landing: "",
    insurance: "",
    trade_license: "",
    vat_reg_certificate: "",
    load_commercial_invoice: "",
    agreement_abay: "",
    tax_id_no: "",
    container_type: "",
    unit_measurment: "",
  });

  const filtered = units.filter((unit) => {
    if (state?.cargo_type == 2) {
      return unit.unit_name === "quintal";
    } else if (state?.cargo_type == 3) {
      return unit.unit_name === "quintal";
    } else if (state?.cargo_type == 4) {
      return unit.unit_name === "number";
    } else if (state?.cargo_type == 5) {
      return unit.unit_name === "quintal";
    } else if (state?.cargo_type == 6) {
      return unit.unit_name === "quintal";
    } else if (state?.cargo_type == 7) {
      return unit.unit_name === "quintal";
    }else if (state?.cargo_type == 8) {
      return unit.unit_name === "quintal";
    }else if (state?.cargo_type == 9) {
      return unit.unit_name === "quintal";
    }else if (state?.cargo_type == 1) {
      return unit.unit_name === "number";
    }
  });

  const getProfileDetails = () => {
    setLoading(true);

    postWithAuthCallWithErrorResponse(
      ApiConfig.PROFILE,
      JSON.stringify({
        ...customerData,
      })
    ).then((res) => {
      if (res.json.message === 
        "Invalid user authentication,Please try to relogin with exact credentials.") {
          localStorage.clear();
          window.open("/login", "_self");
      }
      if (res.json.result) setProfileDetails(res.json);
      setLoading(false);
    });
  };

  useEffect(() => {
    getCargoTypes();
    getdeliveryTypes();
    getContainerTypes();
    getProfileDetails();
  }, []);
  const getCargoTypes = () => {
    postWithAuthCallWithErrorResponse(
      ApiConfig.DROP_DOWNS_API,
      JSON.stringify({ ...customerData })
    )
      .then((res) => {
        if (res.json.message === 
          "Invalid user authentication,Please try to relogin with exact credentials.") {
            localStorage.clear();
            window.open("/login", "_self");
        }
        if (res.json.result) {
          setCargoType(res.json.cargo_types);
        }
      })
      .catch((err) => console.log(err));
  };
  const getdeliveryTypes = () => {
    postWithAuthCallWithErrorResponse(
      ApiConfig.DROP_DOWNS_API,
      JSON.stringify({ ...customerData })
    )
      .then((res) => {
        if (res.json.message === 
          "Invalid user authentication,Please try to relogin with exact credentials.") {
            localStorage.clear();
            window.open("/login", "_self");
        }
        if (res.json.result) {
          setDeliveryType(res.json.delivery_types);
          setUnits(res.json.quantity_units);
        }
      })
      .catch((err) => console.log(err));
  };
  const getContainerTypes = () => {
    postWithAuthCallWithErrorResponse(
      ApiConfig.CONTAINER_TYPES,
      JSON.stringify({ ...customerData })
    )
      .then((res) => {
        if (res.json.message === 
          "Invalid user authentication,Please try to relogin with exact credentials.") {
            localStorage.clear();
            window.open("/login", "_self");
        }
        if (res.json.result) {
          setContainerTypes(res.json.company_type);
        }
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    setIsSubmitted(true);
  }, []);
  const continueNext = () => {
    // if (file.size < 2000000)

    if (isSubmitted) {
      if (
      //state.start_date === "" ||
      state.delivery_date === "" ||
      from_address === "" ||
      state.units === "" ||
      to_address === "" ||
      //state.end_date === "" ||
      state.company_name === "" ||
      state.cargo_type === "" ||
      state.quantity === "" ||
      state.cargo_type?.cargo_id === 9 ? state.other_cargo_type === "" : false || 
      cargoDeliveryValue ===  "No" ? state?.delivery_types === "" : false ||
      cargoDeliveryValue === "No" ? !packing_list?.name : false ||
      // !bill_of_landing.name ||
      cargoDeliveryValue === "No" ? !insurance.name : false ||
      corporateCustomer === "Yes" ? !vat_reg_certificate.name : false ||
      corporateCustomer === "Yes" ? !trade_license.name : false ||
      corporateCustomer === "Yes" ? !tax_id_no.name : false ||
      from_Coordinates?.from_lat === "" ||
      from_Coordinates?.from_lon === "" ||
      to_Coordinates?.to_lat === "" ||
      to_Coordinates?.to_lon === ""
    ) {
      // if (!state.start_date) {
      //   setErrMsg({ ...errMsg, start_date: "Start Date is requied" });
      //   window.scrollTo(0, 0);

      //   return;
      // }

      if (!state.delivery_date) {
        setErrMsg({ ...errMsg, delivery_date: "Delivery Date is required" });
        window.scrollTo(0, 0);
        return;
      }

      if (!from_address === "") {
        setErrMsg({ ...errMsg, from_address: "Address is required" });
        window.scrollTo(0, 0);

        return;
      }
      // if (state.end_date === "") {
      //   setErrMsg({ ...errMsg, end_date: "End Date is required" });
      //   window.scrollTo(0, 0);

      //   return;
      // }
      if (!to_address === "") {
        setErrMsg({ ...errMsg, to_address: "Address is required" });
        window.scrollTo(0, 0);

        return;
      }

      if (state.company_name === "") {
        setErrMsg({ ...errMsg, company_name: "Company is required" });

        window.scrollTo(0, 0);

        return;
      }
      if (state.cargo_type === "") {
        setErrMsg({ ...errMsg, cargo_type: "Cargo Type is required" });
        window.scrollTo(0, 0);

        return;
      }
      if (state.quantity === "") {
        setErrMsg({ ...errMsg, quantity: "Quantity is required" });
        window.scrollTo(0, 0);

        return;
      }
      if (state.cargo_type?.cargo_id === 6 && state.other_cargo_type === "") {
        setErrMsg({ ...errMsg, other_cargo_type: "Other Cargo Type is required" });
        window.scrollTo(0, 0);

        return;
      }
      if (state.units === "") {
        setErrMsg({ ...errMsg, units: "Unit is required" });

        window.scrollTo(0, 0);

        return;
      }
      if (state.delivery_types === "") {
        setErrMsg({ ...errMsg, delivery_types: "Delivery Type is required" });
        window.scrollTo(0, 0);

        return;
      }

      if (!packing_list.name && cargoDeliveryValue === "No") {
        setErrMsg({
          ...errMsg,
          packing_list: "Packing Document is required",
        });
        window.scrollTo(0, 0);

        return;
      }
      if (!tax_id_no.name && corporateCustomer === "Yes") {
        setErrMsg({
          ...errMsg,
          tax_id_no: "TIN (Tax Identification No) Document is required",
        });
        window.scrollTo(0, 0);

        return;
      }
      if (!trade_license.name && corporateCustomer === "Yes") {
        setErrMsg({
          ...errMsg,
          trade_license: "Trading License Document is required",
        });
        window.scrollTo(0, 0);

        return;
      }
      if (!vat_reg_certificate.name && corporateCustomer === "Yes") {
        setErrMsg({
          ...errMsg,
          vat_reg_certificate: "VAT Registration Certificate Document is required",
        });
        window.scrollTo(0, 0);

        return;
      }
      if (!insurance.name) {
        setErrMsg({ ...errMsg, insurance: "Insurance is required" });
        window.scrollTo(0, 0);

        return;
      }
      if (
        from_Coordinates?.from_lat === "" ||
        from_Coordinates?.from_lon === ""
      ) {
        swal({
          icon: "error",
          title: "Invalid Address",
          text: "Please insert the valid address",
        });
        return;
      }
      if (to_Coordinates?.to_lat === "" || to_Coordinates?.to_lon === "") {
        swal({
          icon: "error",
          title: "Invalid Address",
          text: "Please insert the valid address",
        });
        return;
      }
    } else {
      let formData = new FormData();
      formData.append("api_key", customerData.api_key);
      formData.append("user_id", customerData?.user_id);
      formData.append("customer_id", customerData.customer_id);
      //formData.append("start_date", state.start_date);
      formData.append("delivery_date", state.delivery_date);
      formData.append("from_address", from_address);
      formData.append("to_address", to_address);
      //formData.append("end_date", state.end_date);
      formData.append("company_name", state && state.company_name);
      formData.append("cargo_type", state.cargo_type);
      formData.append("quantity", state.quantity);
      formData.append("other_cargo_type", state.other_cargo_type);
      formData.append("unit", state.units);
      if (cargoDeliveryValue === "No") {
        formData.append("delivery_type", state.delivery_types);
      } else {
        formData.append("delivery_type", "");
      }
      if (state.cargo_type === "1") {
        formData.append("container_type", state.container_type);
      }else {
        formData.append("container_type", "");
      }
      formData.append("from_lat", from_Coordinates.lat);
      formData.append("from_lon", from_Coordinates.lng);
      formData.append("to_lon", to_Coordinates.lng);
      formData.append("to_lat", to_Coordinates.lat);
      
      if (cargoDeliveryValue === "No") {
        formData.append("packing_list", packing_list);
        formData.append("bill_of_landing", bill_of_landing);
      } 
      if (corporateCustomer === "Yes") {
        formData.append("tax_id_no", tax_id_no);
        formData.append("trade_license", trade_license);
        formData.append("vat_reg_certificate", vat_reg_certificate);
      } 
      // else {
      //   formData.append("packing_list", packing_list);
      //   formData.append("bill_of_landing", bill_of_landing);
      // }
      
      if (cargoDeliveryValue === "No") {
        formData.append("insurance", insurance);
      }
      state.images.map((img) => {
        formData.append("images[]", img);
      });
      formData.append('cargoDeliveryValue',  cargoDeliveryValue);
      formData.append('corporateCustomer',  corporateCustomer);

      setLoading(true);
      postWithAuthCallWithErrorResponse(ApiConfig.ADD_LOAD_API, formData)
        .then((res) => {
          if (res.json.message === 
            "Invalid user authentication,Please try to relogin with exact credentials.") {
              localStorage.clear();
              window.open("/login", "_self");
          }
          swal(res.json.message);
          if (res.json.result) navigate("/OfferGoodsList");
          setLoading(false);
        })
        .catch((err) => console.log(err));
    }
    }
    
  };
  const timedate = () => {
    let val = new Date().toISOString().split(":")[0] + ":" + new Date().toISOString().split(":")[1];
    return  val;
  }
  timedate();
  const removeImage = (index) => {
    let images = state.images;
    images = images.filter((img) => images.indexOf(img) != index);
    setState({ ...state, images: images });
  };

  const fromHandleSelect = async (value) => {
    const results = await geocodeByAddress(from_address);
    const from_ll = await getLatLng(results[0]);
    setFrom_Address(value);
    setFrom_Coordinates({ ...from_ll });
  };
  const TohandleSelect = async (value) => {
    const results = await geocodeByAddress(to_address);
    const to_ll = await getLatLng(results[0]);
    setTo_Address(value);
    setTo_Coordinates({ ...to_ll });
  };

  const resetInputField = () => {
    setState({
      start_date: "",
      delivery_date: "",
      start_time: "",
      from_lat: "",
      from_lon: "",
      from_address: "",
      to_address: "",
      end_date: "",
      end_time: "",
      to_lat: "",
      to_lon: "",
      company_name: "",
      cargo_type: "",
      quantity: "",
      other_cargo_type: "",
      units: "",
      delivery_types: "",
      packing_list: "",
      bill_of_landing: "",
      insurance: "",
      trade_license: "",
      vat_reg_certificate: "",
      agreement_abay: "",
      tax_id_no: "",
      load_commercial_invoice: "",
      images: [],
    });
    setTo_Address("");
    setFrom_Address("");
    setBill_of_landing("");
    setInsurance("");
    setPacking_list("");
    setCargoType("");
    setDeliveryType("");
  };

  return (
    <main className={`vehicle-list cx-active-sidebar ${mainClass}`} id="cxMain">
      {loading && <Barloader />}
      <section className="documents-section">
        <div className="common-body mt-4 p-3">
          <div className="row">
            {/* <div className="top-content mb-3">
            <h2 className="card-heading align-items-center mt-4">Delivery</h2>
              </div> */}
              <div className="top-content">
                  <h5 className="card-heading">Start Date </h5>
                  <div className="form-check  check-time">
                    {/* <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckChecked"
                    /> */}
                    {/* <label
                      className="form-check-label"
                      htmlFor="flexCheckChecked"
                    >
                      Date
                    </label> */}
                  </div>
                  <hr />
                </div>
                <div className="col-lg-12">
                  <div className="form-group mt-4">
                    <label htmlFor="exampleInputEmail1">
                      Delivery Start Date
                    </label>
                    <input
                      type="datetime-local"
                      style={{width: 599}}
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      value={state.delivery_date}
                      min={timedate() + ""}
                      onChange={(e) => {
                        setErrMsg({ ...errMsg, delivery_date: "" });
                        setState({ ...state, delivery_date: e.target.value });
                      }}
                    />
                    {errMsg.delivery_date.length > 0 && (
                      <div className="text-center text-danger">
                        <span> {errMsg.delivery_date}</span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              </div>
              </section>
      {/* From To section */}
      <section className="from-to-section">
        <div className="row">
          <div className="col-lg-6">
            
            <div className="left-section common-body p-3 mt-4">
              <div className="row">
                <div className="top-content">
                  <h5 className="card-heading">From </h5>
                  {/* <div className="form-check  check-time">
                    
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckChecked"
                    >
                      Date
                    </label>
                  </div> */}
                  <hr />
                </div>
                {/* <div className="col-lg-12">
                  <div className="form-group mt-4">
                    <label htmlFor="exampleInputEmail1">
                      Expected Arrival Date
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      value={state.start_date}
                      onChange={(e) => {
                        setErrMsg({ ...errMsg, start_date: "" });
                        setState({ ...state, start_date: e.target.value });
                      }}
                    />
                    {errMsg.start_date.length > 0 && (
                      <div className="text-center text-danger">
                        <span> {errMsg.start_date}</span>
                      </div>
                    )}
                  </div>
                </div> */}
                <div className="col-12">
                  <div className="form-group mt-4">
                    <label htmlFor="exampleFormControlTextarea1">Address</label>
                    <PlacesAutocomplete
                      value={from_address}
                      onChange={setFrom_Address}
                      onSelect={fromHandleSelect}
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div>
                          <textarea
                            {...getInputProps({
                              placeholder: "Search Places ...",
                              className: "location-search-input form-control",
                            })}
                          />
                          <div className="autocomplete-dropdown-container">
                            {loading && <div>Loading...</div>}
                            {suggestions.map((suggestion) => {
                              const className = suggestion.active
                                ? "suggestion-item--active"
                                : "suggestion-item";
                              // inline style for demonstration purpose
                              const style = suggestion.active
                                ? {
                                    backgroundColor: "#fafafa",
                                    cursor: "pointer",
                                  }
                                : {
                                    backgroundColor: "#ffffff",
                                    cursor: "pointer",
                                  };
                              return (
                                <div
                                  {...getSuggestionItemProps(suggestion, {
                                    className,
                                    style,
                                  })}
                                >
                                  <span>{suggestion.description}</span>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div className="col-lg-6">
            <div className="right-section common-body p-3 mt-4">
              <div className="row">
                <div className="top-content">
                  <h5 className="card-heading">To </h5>
                  {/* <div className="form-check  check-time">
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckChecked"
                    >
                      Date
                    </label>
                  </div> */}
                  <hr />
                </div>
                {/* <div className="col-lg-12">
                  <div className="form-group mt-4">
                    <label htmlFor="exampleInputEmail1">
                      Estimated End Date{" "}
                    </label>
                    <input
                      value={state.end_date}
                      type="date"
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      onChange={(e) => {
                        setErrMsg({ ...errMsg, end_date: "" });
                        setState({ ...state, end_date: e.target.value });
                      }}
                    />
                    {errMsg.end_date.length > 0 && (
                      <div className="text-center text-danger">
                        <span> {errMsg.end_date}</span>
                      </div>
                    )}
                  </div>
                </div> */}
                <div className="col-12">
                  <div className="form-group mt-4">
                    <label htmlFor="exampleFormControlTextarea1">Address</label>
                    <PlacesAutocomplete
                      value={to_address}
                      onChange={setTo_Address}
                      onSelect={TohandleSelect}
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div>
                          <textarea
                            {...getInputProps({
                              placeholder: "Search Places ...",
                              className: "location-search-input form-control",
                            })}
                          />
                          <div className="autocomplete-dropdown-container">
                            {loading && <div>Loading...</div>}
                            {suggestions.map((suggestion) => {
                              const className = suggestion.active
                                ? "suggestion-item--active"
                                : "suggestion-item";
                              // inline style for demonstration purpose
                              const style = suggestion.active
                                ? {
                                    backgroundColor: "#fafafa",
                                    cursor: "pointer",
                                  }
                                : {
                                    backgroundColor: "#ffffff",
                                    cursor: "pointer",
                                  };
                              return (
                                <div
                                  {...getSuggestionItemProps(suggestion, {
                                    className,
                                    style,
                                  })}
                                >
                                  <span>{suggestion.description}</span>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Goods Details */}
      <secton className="goods-details ">
        <div className=" common-body mt-4 p-3">
          <div className="row">
            <div className="top-content">
              <h5 className="card-heading">Goods Details</h5>
              <hr />
            </div>
            {/* left section */}
            {/* <div className="col-lg-6 mt-4">
              <div className="top-item">
                <label style={{ color: "#66739B" }}>
                  Upload Freight Images
                </label>
                <DragAndDropImages
                  setFile={(files) => {
                    setErrMsg({ ...errMsg, images: "" });
                    setState({ ...state, images: [...state.images, ...files] });
                    if (
                      !allowedFileTypes.includes(files[0].type) ||
                      files[0].size > 5242880
                    ) {
                      swal({
                        icon: "error",
                        title: "Invalid file type",
                        text: "Please upload a file of type PDF, PNG, or JPEG and should not exceed more than 5MB.",
                      });
                      setState({ ...state, images: [] });
                    }
                  }}
                />
                <div className="uploaded_items d-flex mt-4">
                  {state.images.map((img, index) => {
                    return (
                      <div className="item_list" key={"vehicleImages" + index}>
                        <img
                          width={72}
                          height="72"
                          className="vehicle_img"
                          src={URL.createObjectURL(img)}
                          alt=""
                        />
                        <a href="#" className="close_item">
                          <img
                            src={circle_cross}
                            alt=""
                            onClick={() => removeImage(index)}
                          />
                        </a>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div> */}
            {/* right section */}
            <div className="col-lg-6 mt-4">
              <div className="row">
                <div className="col-12">
                  <div className="form-group mt-4">
                    <label htmlFor="exampleInputEmail1">Company Name</label>
                    <input
                      type="text"
                      value={personInfo && personInfo.company_name}
                      className="form-control"
                      id="exampleInputEmail1"
                      disabled
                      aria-describedby="emailHelp"
                      onChange={(e) => {
                        setErrMsg({ ...errMsg, company_name: "" });
                        setState({ ...state, company_name: e.target.value });
                      }}
                    />
                    {errMsg.company_name.length > 0 && (
                      <div className="text-center text-danger">
                        <span> {errMsg.company_name}</span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group mt-4">
                    <label htmlFor="exampleInputEmail1">Is Delivery?</label>
                    {/* <select
                      className="form-select"
                      aria-label="Default select example"
                      onChange={(e) => {
                        setErrMsg({ ...errMsg, is_cargo_delivery: "" });
                        setState({ ...state, is_cargo_delivery: e.target.value });
                        setCargoDeliveryValue(e.target.value);
                      }}
                    >
                      <option value="Yes" selected>
                        Please select
                      </option>
                      {cargoDelivery &&
                        cargoDelivery.map((cargo, index) => {
                          return (
                            <option
                              disabled
                              key={"cargo_delivery" + index}
                              value={cargo[0]}
                            >
                              {cargo}
                            </option>
                          );
                        })}
                    </select> */}
                    <input
                      type="text"
                      value={cargoDelivery && cargoDelivery[0]}
                      className="form-control"
                      id="exampleInputEmail1"
                      disabled
                      aria-describedby="emailHelp"
                      onChange={(e) => {
                        setErrMsg({ ...errMsg, is_cargo_delivery: "" });
                        setState({ ...state, is_cargo_delivery: "Yes" });
                        setCargoDeliveryValue("Yes");
                      }}
                    />
                    {errMsg.is_cargo_delivery.length > 0 && (
                      <div className="text-center text-danger">
                        <span> {errMsg.is_cargo_delivery}</span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group mt-4">
                    <label htmlFor="exampleInputEmail1">Cargo Type</label>
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      onChange={(e) => {
                        setErrMsg({ ...errMsg, cargo_type: "" });
                        setState({ ...state, cargo_type: e.target.value });
                      }}
                    >
                      <option value="" selected>
                        Please select
                      </option>
                      {cargoType &&
                        cargoType.length &&
                        cargoType.map((cargo, index) => {
                      {/* { [{"cargo_id": 1, "cargo_name": "Container"},
                      {"cargo_id": 2, "cargo_name": "Break bulk"},
                      {"cargo_id": 3, "cargo_name": "Bulk"},
                      {"cargo_id": 4, "cargo_name": "Vehicle"}, 
                      {"cargo_id": 5, "cargo_name": "Marchandise"}, 
                      {"cargo_id": 6, "cargo_name": "House Hold Item"}, 
                      {"cargo_id": 7, "cargo_name": "Building Materials"}, 
                      {"cargo_id": 8, "cargo_name": "Agricultural Materials"},
                      {"cargo_id": 9, "cargo_name": "Other (Specify)"}].map((cargo, index) => { */}
                          return (
                            <option
                              key={"cargo" + index}
                              value={cargo.cargo_id}
                            >
                              {cargo.cargo_name}
                            </option>
                          );
                        })}
                    </select>
                    {errMsg.cargo_type.length > 0 && (
                      <div className="text-center text-danger">
                        <span> {errMsg.cargo_type}</span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group mt-4">
                    <label htmlFor="exampleInputEmail1">Corporate Customer?</label>
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      onChange={(e) => {
                        setErrMsg({ ...errMsg, corporateCustomer: "" });
                        setState({ ...state, corporateCustomer: e.target.value });
                        setCorporateCustomer(e.target.value);
                      }}
                    >
                      <option value="" selected>
                        Please select
                      </option>
                      {["Yes", "No"].map((customer, index) => {
                          return (
                            <option
                              key={"customer" + index}
                              value={customer}
                            >
                              {customer}
                            </option>
                          );
                        })}
                    </select>
                    {errMsg.corporateCustomer.length > 0 && (
                      <div className="text-center text-danger">
                        <span> {errMsg.corporateCustomer}</span>
                      </div>
                    )}
                  </div>
                </div>
                {state.cargo_type == 1 && (
                  <div className="col-lg-12">
                    <div className="form-group mt-4">
                      <label htmlFor="exampleInputEmail1">
                        Type of Containers
                      </label>
                      <select
                        class="form-select"
                        aria-label="Default select example"
                        onChange={(e) => {
                          setErrMsg({ ...errMsg, container_type: "" });
                          setState({
                            ...state,
                            container_type: e.target.value,
                          });
                        }}
                      >
                        <option selected>Please Select</option>
                        {containerTypes &&
                          containerTypes.length &&
                          containerTypes.map((type, index) => {
                            return (
                              <option
                                key={"delivery" + index}
                                value={type.container_type_id}
                              >
                                {type.container_type_name}
                              </option>
                            );
                          })}
                      </select>
                      {errMsg.container_type.length > 0 && (
                        <div className="text-center text-danger">
                          <span> {errMsg.container_type}</span>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {state.cargo_type == 9 && (
                  <div className="col-lg-12">
                  <div className="form-group mt-4">
                    <label htmlFor="exampleInputEmail1">
                      Specify Cargo Type
                    </label>
                    <input
                      type="text"
                      value={state.other_cargo_type}
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      onChange={(e) => {
                        setErrMsg({ ...errMsg, other_cargo_type: "" });
                        setState({ ...state, other_cargo_type: e.target.value });
                      }}
                    />
                    {errMsg.other_cargo_type.length > 0 && (
                      <div className="text-center text-danger">
                        <span> {errMsg.other_cargo_type}</span>
                      </div>
                    )}
                  </div>
                </div>
                )}
                <div className="col-lg-12">
                  <div className="form-group mt-4">
                    <label htmlFor="exampleInputEmail1">
                      Unit Of Measurement
                    </label>

                    <select
                      className="form-select"
                      aria-label="Default select example"
                      onChange={(e) => {
                        setErrMsg({ ...errMsg, units: "" });
                        setState({
                          ...state,
                          units: e.target.value,
                        });
                      }}
                    >
                      <option selected>Please Select</option>

                      {filtered &&
                        filtered.length &&
                        filtered.map((units, index) => {
                          return (
                            <option key={"units" + index} value={units.unit_id}>
                              {units.unit_name}
                            </option>
                          );
                        })}
                    </select>
                    {errMsg.units.length > 0 && (
                      <div className="text-center text-danger">
                        <span> {errMsg.units}</span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group mt-4">
                    <label htmlFor="exampleInputEmail1">
                      Quantity (Total Volume)
                    </label>
                    <input
                      type="number"
                      value={state.quantity}
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      onChange={(e) => {
                        setErrMsg({ ...errMsg, quantity: "" });
                        setState({ ...state, quantity: e.target.value });
                      }}
                    />
                    {errMsg.quantity.length > 0 && (
                      <div className="text-center text-danger">
                        <span> {errMsg.quantity}</span>
                      </div>
                    )}
                  </div>
                </div>
                
                {(cargoDeliveryValue === "No") ? 
                <><div className="col-lg-12">
                  <div className="form-group mt-4">
                    <label htmlFor="exampleInputEmail1">Delivery Type</label>
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      onChange={(e) => {
                        setErrMsg({ ...errMsg, delivery_types: "" });
                        setState({
                          ...state,
                          delivery_types: e.target.value,
                        });
                        // setCargoDeliveryValue(e.target.value);
                      }}
                    >
                      <option value="">Please select</option>
                      {deliveryType &&
                        deliveryType.length &&
                        deliveryType.map((deliveryType, index) => {
                          return (
                            <option
                              key={"delivery" + index}
                              value={deliveryType.delivery_type_name}
                            >
                              {deliveryType.delivery_type_name}
                            </option>
                          );
                        })}
                    </select>
                    {errMsg.delivery_types.length > 0 && (
                      <div className="text-center text-danger">
                        <span> {errMsg.delivery_types}</span>
                      </div>
                    )}
                  </div>
                </div> </>: <></>}
              </div>
            </div>
          </div>
        </div>
      </secton>
      {/* Documents section */}
      {(corporateCustomer === "Yes")  ?<>
      <section className="documents-section">
        <div className="common-body mt-4 p-3">
          <div className="row">
            <div className="top-content mb-3">
              <h5 className="card-heading">Documents</h5>
              <hr />
              {fileSize && fileSize > 5242880 && (
                <p className="text-danger">
                  File size cannot exceed more than 5MB
                </p>
              )}
            </div>
            <div className="col-lg-4">
              <label htmlFor="">(TIN)Tax Identification No</label>
              <DragAndDrop
                setFile={(file) => {
                  setFileSize(file.size);
                  setErrMsg({ ...errMsg, tax_id_no: "" });
                  let reader = new FileReader();
                  reader.onload = function (e) {
                    setState({
                      ...state,
                      tax_id_no: reader.result,
                    });
                  };
                  reader.readAsDataURL(file);
                  setTax_id_no(file);

                  if (
                    !allowedFileTypes.includes(file.type) ||
                    file.size > 5242880
                  ) {
                    swal({
                      icon: "error",
                      title: "Invalid file type",
                      text: "Please upload a file of type PDF, PNG, or JPEG and should not exceed more than 5MB.",
                    });
                    setTax_id_no({});
                  }
                }}
                id="tax_id_no"
              />

              <div className="uploaded_file d-flex justify-content-between">
                <label
                  className="col-4 text-truncate"
                  data-bs-toggle="modal"
                  data-bs-target="#packinglist"
                >
                  {tax_id_no && tax_id_no.name && tax_id_no.name}
                </label>

                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    setTax_id_no({});
                    setState({ ...state, tax_id_no: "" });
                  }}
                >
                  <img src={ic_remove} alt="" />
                </a>
              </div>
              {state.tax_id_no && (
                <a
                  href={ApiConfig.BASE_URL_FOR_IMAGES + state.tax_id_no}
                  className="preview_link"
                  target="_blank"
                  // data-bs-toggle="modal"
                  // data-bs-target="#TIN_Modal"
                >
                  Preview
                </a>
              )}
              {errMsg.tax_id_no.length > 0 && (
                <div className="text-center text-danger">
                  <span> {errMsg.tax_id_no}</span>
                </div>
              )}
            </div>
            {/* <!-- Modal --> */}
            <div
              class="modal fade"
              id="packinglist"
              tabindex="-1"
              aria-labelledby="packinglist"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                  <div class="modal-body">
                    <img
                      width="600px"
                      src={state && state.tax_id_no}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            { (
              <div className="col-lg-4 col-sm-12">
                <label htmlFor="">Trade License</label>
                <DragAndDrop
                  setFile={(file) => {
                    setFileSize(file.size);
                    setErrMsg({ ...errMsg, trade_license: "" });
                    let reader = new FileReader();
                    reader.onload = function (e) {
                      setState({
                        ...state,
                        trade_license: reader.result,
                      });
                    };
                    reader.readAsDataURL(file);
                    setTrade_license(file);
                    if (
                      !allowedFileTypes.includes(file.type) ||
                      file.size > 5242880
                    ) {
                      swal({
                        icon: "error",
                        title: "Invalid file type",
                        text: "Please upload a file of type PDF, PNG, or JPEG and should not exceed more than 5MB.",
                      });
                      setTrade_license({});
                    }
                  }}
                  id="trade_license"
                />

                <div className="uploaded_file d-flex justify-content-between">
                  <label data-bs-toggle="modal" data-bs-target="#billoflanding">
                    {trade_license &&
                      trade_license.name &&
                      trade_license.name}
                  </label>
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      setTrade_license({});
                      setState({ ...state, trade_license: "" });
                    }}
                  >
                    <img src={ic_remove} alt="" />
                  </a>
                </div>
                {state.trade_license && (
                  <a
                    href={ApiConfig.BASE_URL_FOR_IMAGES + state.trade_license}
                    className="preview_link"
                    target="_blank"
                    // data-bs-toggle="modal"
                    // data-bs-target="#TIN_Modal"
                  >
                    Preview
                  </a>
                )}
                {errMsg.trade_license.length > 0 && (
                  <div className="text-center text-danger">
                    <span> {errMsg.trade_license}</span>
                  </div>
                )}
              </div>
            )}
            {/* <!-- Modal --> */}
            <div
              class="modal fade"
              id="billoflanding"
              tabindex="-1"
              aria-labelledby="billoflanding"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                  <div class="modal-body">
                    <img
                      width="600px"
                      src={state && state.trade_license}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <label htmlFor="">VAT Registration Certificate</label>
              <DragAndDrop
                setFile={(file) => {
                  setFileSize(file.size);
                  setErrMsg({ ...errMsg, vat_reg_certificate: "" });
                  let reader = new FileReader();
                  reader.onload = function (e) {
                    setState({
                      ...state,
                      vat_reg_certificate: reader.result,
                    });
                  };
                  reader.readAsDataURL(file);
                  setVat_reg_certificate(file);
                  if (
                    !allowedFileTypes.includes(file.type) ||
                    file.size > 5242880
                  ) {
                    swal({
                      icon: "error",
                      title: "Invalid file type",
                      text: "Please upload a file of type PDF, PNG, or JPEG and should not exceed more than 5MB.",
                    });
                    setVat_reg_certificate({});
                  }
                }}
                id="vat_reg_certificate"
              />

              <div className="uploaded_file d-flex justify-content-between">
                <label data-bs-toggle="modal" data-bs-target="#insurance_modal">
                  {vat_reg_certificate && vat_reg_certificate.name && vat_reg_certificate.name}
                </label>
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    setVat_reg_certificate({});
                    setState({ ...state, vat_reg_certificate: "" });
                  }}
                >
                  <img src={ic_remove} alt="" />
                </a>
              </div>
              {state.vat_reg_certificate && (
              <a
                href={ApiConfig.BASE_URL_FOR_IMAGES + state.vat_reg_certificate}
                className="preview_link"
                target="_blank"
                // data-bs-toggle="modal"
                // data-bs-target="#TIN_Modal"
              >
                Preview
              </a>
            )}
            </div>
            
            {errMsg.vat_reg_certificate.length > 0 && (
              <div className="text-center text-danger">
                <span> {errMsg.vat_reg_certificate}</span>
              </div>
            )}
            {/* <!-- Modal --> */}
            <div
              class="modal fade"
              id="insurance_modal"
              tabindex="-1"
              aria-labelledby="insurance_modal"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                  <div class="modal-body">
                    <img width="600px" src={state && state.vat_reg_certificate} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section></> : <section className="documents-section">
        <div className="common-body mt-4 p-3">
          <div className="row">
            <div className="top-content mb-3">
            <h2 className="card-heading align-items-center mt-4">Delivery</h2>
              </div>
              </div>
              </div>
              </section>}

      <div className="text-center fix-bottom-bar d-flex justify-content-between align-items-center mt-4">
        <div className="left"></div>
        <div className="right">
          {/* <Link to="#"> */}
          <button
            type="cancel"
            className="cancel-btn"
            onClick={() => resetInputField()}
          >
            Cancel
          </button>
          {/* </Link> */}
          {/* <Link to="/DetailsOfferGoods" > */}
          <button type="submit" className="continue-btn" onClick={() => {
              continueNext();              
              setIsSubmitted(false);
            }
          }>
            Send Offer
          </button>
          {/* </Link> */}
        </div>
      </div>
    </main>
  );
};

export default AddOfferGoods;
