import { AppContext } from "./AppContext";

import React, { useState } from "react";

const AppState = (props) => {
  const [sidebar, setSidebar] = useState(false);
  const [mainClass, setMainClass] = useState("cx-active-sidebar");
  const [mainClassB, setMainClassB] = useState("cx-active-sidebarB");
  const [OfferGoodsList, setOfferGoodsList] = useState([]);
  const [transpoterLists, setTranspoterLists] = useState([]);
  const [vehicleOffer, setVehicleOffer] = useState([]);

  const [cargoType, setCargoType] = useState([]);
  const [deliveryType, setDeliveryType] = useState([]);
  let shipperData = JSON.parse(sessionStorage.getItem("shipperData"));

  const [shipperDetails, setShipperDetails] = useState({
    ...shipperData,
    confirmPassword: "",
  });
  const [customerData, setCustomerData] = useState({
    customer_id: localStorage.getItem("customer_id")
      ? localStorage.getItem("customer_id")
      : null,
    user_id: localStorage.getItem("user_id")
      ? localStorage.getItem("user_id")
      : null,
    api_key: localStorage.getItem("api_key")
      ? localStorage.getItem("api_key")
      : null,
  });

  const [userDetails, SetUserDetails] = useState(
    localStorage.getItem("userDetails")
      ? JSON.parse(localStorage.getItem("userDetails"))
      : null
  );

  const [customerDetails, setCustomerDetails] = useState({
    name: localStorage.getItem("name") ? localStorage.getItem("name") : null,
    email: localStorage.getItem("email") ? localStorage.getItem("email") : null,
    user_profile_pic: localStorage.getItem("user_profile_pic")
      ? localStorage.getItem("user_profile_pic")
      : null,
  });

  const [profileDetails, setProfileDetails] = useState({
    basic_info: {},
    contact_person: {},
    address: {},
    documents: {},
    profile: {},
  });

  return (
    <AppContext.Provider
      value={{
        customerDetails,
        setCustomerDetails,
        sidebar,
        setSidebar,
        SetUserDetails,
        userDetails,
        customerData,
        setCustomerData,
        mainClass,
        shipperDetails,
        setShipperDetails,
        setMainClass,
        OfferGoodsList,
        setOfferGoodsList,
        cargoType,
        setCargoType,
        deliveryType,
        setDeliveryType,
        vehicleOffer,
        setVehicleOffer,
        transpoterLists,
        setTranspoterLists,
        mainClassB,
        setMainClassB,
        profileDetails,
        setProfileDetails,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

export default AppState;
