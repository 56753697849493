import React, { useState, useContext, useEffect } from "react";
import ic_options_menu from "../../assets/images/ic_options_menu.svg";
import document_image from "../../assets/images/document_image.png";
import Skyscrapers from "../../assets/images/Skyscrapers.jpg";
import { AppContext } from "../../context/user/AppContext";
import { postWithAuthCallWithErrorResponse } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import Loader from "../../sharedComponent/Loader";
import { Link, useLocation, useParams } from "react-router-dom";

const DetailsOfferGoods = () => {
  const params = useParams();
  let id = params.id;
  const goods = useLocation().state;
  const [offerGoodsDetails, setOfferGoodsDetails] = useState({
    ...goods,
    trip_images: [],
  });

  const { mainClass, customerData } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getOfferGoodsDetails();
  }, []);
  const getOfferGoodsDetails = () => {
    setLoading(true);
    
    postWithAuthCallWithErrorResponse(
      ApiConfig.OFFER_LOAD_DETAILS,
      JSON.stringify({ ...customerData, load_id: id })
    ).then((res) => {
      if (res.json.message === 
        "Invalid user authentication,Please try to relogin with exact credentials.") {
          localStorage.clear();
          window.open("/login", "_self");
      }
      setLoading(false);
      if (res.json.result) setOfferGoodsDetails(res.json.load_details);
    });
  };

  return (
    <main className={`vehicle-list details ${mainClass}`} id="cxMain">
      {/* From details section */}
      {loading ? (
        <Loader loading={loading} />
      ) : (
        <>
        <section className="from-details">
            <div className="common-body mt-4 p-3">
              <div className="row">
              <div className="top-content ">
                  <h5 className="card-heading">Delivery Date</h5>
                  <hr />
                </div>
                <div className="col-lg-3 col-md-6 ">
                  <label htmlFor="">Trip Delivery Date</label>
                  <p className="data">{offerGoodsDetails.trip_delivery_date}</p>
                </div>
                <div>

                </div>
              </div>
            </div>
          </section>
          <section className="from-details">
            <div className="common-body mt-4 p-3">
              <div className="row">
                <div className="top-content ">
                  <h5 className="card-heading">From</h5>
                  <hr />
                </div>
                

                <div className="col-lg-3 col-md-6">
                  <label htmlFor="">Country</label>
                  <p className="data">{offerGoodsDetails.trip_start_country}</p>
                </div>
                <div className="col-lg-3 col-md-6">
                  <label htmlFor="">City</label>
                  <p className="data">{offerGoodsDetails.trip_start_city}</p>
                </div>
                <div className="col-lg-3 col-md-6">
                  <label htmlFor="">Address</label>
                  <p className="data">{offerGoodsDetails.trip_start_address}</p>
                </div>
              </div>
            </div>
          </section>
          {/* To details section */}
          <section className="to-details">
            <div className="common-body mt-4 p-3">
              <div className="row">
                <div className="top-content ">
                  <h5 className="card-heading">To</h5>
                  <hr />
                </div>
                

                <div className="col-lg-3 col-md-6">
                  <label htmlFor="">Country</label>
                  <p className="data"> {offerGoodsDetails.trip_end_country}</p>
                </div>
                <div className="col-lg-3 col-md-6">
                  <label htmlFor="">City</label>
                  <p className="data"> {offerGoodsDetails.trip_end_city}</p>
                </div>
                <div className="col-lg-3 col-md-6">
                  <label htmlFor="">Address</label>
                  <p className="data">{offerGoodsDetails.trip_end_address}</p>
                </div>
              </div>
            </div>
          </section>
          {/* Goods details */}
          <section className="goods-details mb-4">
            <div className="common-body mt-4 p-3">
              <div className="row">
                <div className="top-content ">
                  <h5 className="card-heading">Goods Details</h5>
                  <hr />
                </div>
                {/* left section */}
                {offerGoodsDetails?.trip_images.length > 0 && (
                  <div className="col-lg-6">
                    <div className="image">
                      <div
                        id="carouselExampleControls"
                        className="carousel slide"
                        data-bs-ride="carousel"
                      >
                        {offerGoodsDetails?.trip_images.map((img, index) => {
                          
                          return (
                            <>
                              <div
                                className={
                                  index == 0
                                    ? "carousel-item active"
                                    : "carousel-item"
                                }
                                key={img.vehicle_image_id}
                              >
                                <img
                                  width="515px"
                                  height="300px"
                                  src={
                                    ApiConfig.BASE_URL_FOR_IMAGES +
                                    img.trip_image_url
                                  }
                                  className="d-block "
                                  alt="..."
                                />
                              </div>
                            </>
                          );
                        })}
                        <button
                          className="carousel-control-prev"
                          type="button"
                          data-bs-target="#carouselExampleControls"
                          data-bs-slide="prev"
                        >
                          <span
                            className="carousel-control-prev-icon"
                            aria-hidden="true"
                          ></span>
                          <span className="visually-hidden">Previous</span>
                        </button>
                        <button
                          className="carousel-control-next"
                          type="button"
                          data-bs-target="#carouselExampleControls"
                          data-bs-slide="next"
                        >
                          <span
                            className="carousel-control-next-icon"
                            aria-hidden="true"
                          ></span>
                          <span className="visually-hidden">Next</span>
                        </button>
                      </div>
                    </div>
                  </div>
                )}
                {/* right section */}
                <div
                  className={
                    offerGoodsDetails?.trip_images.length > 0
                      ? "col-lg-6"
                      : "col"
                  }
                >
                  <div className="row">
                    <div className="col-lg-3 mt-4">
                      <label htmlFor="">Reference Number</label>
                      <p className="data">
                        {" "}
                        {offerGoodsDetails.trip_reference_no}
                      </p>
                    </div>
                    <div className="col-lg-3 mt-4">
                      <label htmlFor="">Company Name</label>
                      <p className="data">
                        {" "}
                        {offerGoodsDetails.trip_company_name}
                      </p>
                    </div>
                    <div className="col-lg-3 mt-4">
                      <label htmlFor="">Cargo Type</label>
                      <p className="data"> {offerGoodsDetails.cargo_type === "Other (Specify)" ? offerGoodsDetails.trip_other_cargo_type : offerGoodsDetails.cargo_type}</p>
                    </div>
                    {offerGoodsDetails.cargo_type === "Container" && (
                      <div className="col-lg-3 mt-4">
                        <label htmlFor="">Container Type</label>
                        <p className="data">
                          {" "}
                          {offerGoodsDetails.container_type}
                        </p>
                      </div>
                    )}

                    <div className="col-lg-3 mt-4">
                      <label htmlFor="">Quantity</label>
                      <p className="data"> {offerGoodsDetails.quantity}</p>
                    </div>
                    {/* <div className="col-lg-3 mt-4">
                      <label htmlFor="">Delivery Type</label>
                      <p className="data"> {offerGoodsDetails.delivery_type}</p>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* Document Details */}
          
        </>
      )}
    </main>
  );
};

export default DetailsOfferGoods;
